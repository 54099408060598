import { Editor, Admin, Support } from '@/router'
export default [
  {
    title: 'الرئيسية',
    route: '/admin/home',
    icon: 'estate',
    roles: [Admin]
  },
  {
    header: 'الأقسام الرئيسية',
    roles: [Admin]
  },
  {
    title: 'الخدمات',
    route: '/admin/main/services',
    icon: 'briefcase-alt',
    roles: [Admin, Editor]
  },
  {
    title: 'المنتجات',
    route: '/admin/main/products',
    icon: 'bookmark',
    roles: [Admin, Editor]
  },
  {
    title: 'النشاطات',
    route: '/admin/main/activites',
    icon: 'server',
    roles: [Admin, Editor]
  },
  {
    title: 'الموظفين',
    route: '/admin/main/employees',
    icon: 'users-alt',
    roles: [Admin]
  },
  {
    title: 'بريد الشركة',
    route: '/admin/main/inbox',
    icon: 'archive-alt',
    roles: [Admin, Support]
  },
  {
    title: 'الزبائن',
    route: '/admin/main/customers',
    icon: 'user-circle',
    roles: [Admin]
  },
  {
    header: 'الإعدادات',
    roles: [Admin]
  },
  {
    title: 'حسابات المستخدمين',
    route: '/admin/user-accounts',
    icon: 'user-square',
    roles: [Admin]
  },
  {
    title: 'التصنيفات',
    route: '/admin/categories',
    icon: 'vertical-distribution-center',
    roles: [Admin, Editor]
  },
  {
    icon:'setting',
    title: 'إعداد الصفحات',
    roles: [Admin],
    children: [
      {
        title: 'إعدادات الخدمة ',
        route: '/admin/service-setting',
        icon: 'shopping-bag',
        roles: [Admin],
      },
      {
        title: 'إعدادات المنتج',
        route:'/admin/product-setting',
        icon: 'briefcase',
        roles: [Admin],
      },
    ]
  },
  {
    title: 'إعدادات الشركة',
    route: '/admin/company-setting',
    icon: 'wrench',
    roles: [Admin],
  },
  {
    title: 'ملف الشركة',
    route: '/admin/company-info',
    icon: 'files-landscapes',
    roles: [Admin],
  }
]
