
<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <b-button
      v-if="isAdmin()"
      variant="flat-secondary"
      class="btn-icon rounded-circle"
      @click="exchangeRate = true"
    >
      <unicon name="dollar-alt" width="18" height="18" />
    </b-button>

    <!-- Left Col -->
    <app-navbar-vertical-layout class="ml-auto" />

    <b-navbar-nav class="nav align-items-center">
      <user-dropdown />
    </b-navbar-nav>

    <ValidationObserver ref="currencyForm">
      <b-modal v-model="exchangeRate">
        <template #modal-title
          ><strong class="text-primary">تعديل سعر الصرف</strong></template
        >
        <div>
          <BTextInputWithValidation
            rules="required"
            type="number"
            label="سعر الصرف الحالي"
            name="سعر الصرف"
            placeholder="أدخل سعر الصرف"
            v-model="currency.value"
          />
          <div class="mr-auto d-flex align-items-center">
            <b-form-checkbox switch v-model="currency.certified"></b-form-checkbox>
            <label class="pr-1 m-0">إعتماد سعر الصرف</label>
          </div>
        </div>
        <template #modal-footer>
          <div class="mr-auto d-flex align-items-center">
            <b-form-checkbox v-model="settings.showPrices"></b-form-checkbox>
            <label class="pr-1 m-0">إظهار الاسعار</label>
          </div>
          <b-button variant="primary" @click="submitForm">تعديل</b-button>
          <b-button variant="outline-primary" @click="exchangeRate = false"
            >إلغاء</b-button
          >
        </template>
      </b-modal>
    </ValidationObserver>
  </div>
</template>
<script>
import AppNavbarVerticalLayout from "@core/layouts/components/app-navbar/AppNavbarVerticalLayout.vue";
import UserDropdown from "@core/layouts/components/app-navbar/components/UserDropdown.vue";
import BTextInputWithValidation from "@core/components/inputs/BTextInputWithValidation";
import { mapActions, mapGetters } from "vuex";
import { getUserData } from "@/auth/utils";
import { Admin } from "@/router";
import { ValidationObserver } from "vee-validate";

export default {
  components: {
    UserDropdown,
    BTextInputWithValidation,
    ValidationObserver,
    // Navbar Components
    AppNavbarVerticalLayout,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(["settings", "currency"]),
  },
  data: () => ({
    exchangeRate: null,
  }),
  created() {
    this.fetchSettings();
    this.getCurrency();
  },
  methods: {
    ...mapActions([
      "fetchSettings",
      "updateShowPrices",
      "getCurrency",
      "editCurrencyPrice",
    ]),
    submitForm() {
      this.$refs.currencyForm.validate().then((success) => {
        if (success) {
          this.editCurrencyPrice(this.currency);
          this.updateShowPrices({
            id: this.settings.id,
            showPrices: this.settings.showPrices,
          });
        }
      });
    },
    isAdmin() {
      return getUserData().role == Admin;
    },
  },
};
</script>
